// CalendlyComponent.js
import React, { useEffect } from 'react';
import * as Icon from 'react-feather';

const CalendlyPage = ({ handleClose }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className='calendly'>
      <iframe title="Calendly Widget" src="https://calendly.com/techmet-demo/booking" frameBorder="0" width="80%" height="80%"></iframe>
      <div className="calendly-close">
        <Icon.X onClick={() => handleClose(false)} style={{cursor: 'pointer'}}/>
      </div>
    </div>
  );
};

export default CalendlyPage;
